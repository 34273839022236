/* .................... */

.footer-wrapper {
  width: 100%;

  background-color: #7420d5;
  color: white;
}
.footer {
  margin: 0;
  text-align: center;
  padding: 40px 10%;
}
.visible {
  visibility: hidden;
  border-top: 1px solid rgb(241, 234, 234);
  height: 2px;
}
.logo-col {
  display: flex;
  justify-content: last baseline;
  align-items: center;
}
.logo-item {
  display: flex;
  padding-right: 30px;
}
.company-col,
.terms-col {
  padding-right: 30px;
}
.follow-us {
}
.company-col h2,
.terms-col h2,
.follow-us-col h2 {
  text-transform: capitalize;
  font-size: 2.2rem;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
}
.company-col h2::before,
.terms-col h2::before,
.follow-us-col h2::before {
  content: '';
  position: absolute;
  /* left: 0; */
  bottom: -8px;
  background-color: rgb(241, 234, 234);
  height: 2px;
  box-sizing: border-box;
  width: 50px;
  /* for center aligning */
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.company-col ul,
.terms-col ul {
  text-transform: capitalize;
  list-style-type: none;
  margin: 40px 0;
}
.company-col ul li,
.terms-col ul li {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #e0dcdc;
  transition: all 0.5s ease;
}
.company-col ul li:hover,
.terms-col ul li:hover {
  color: white;
  padding-left: 10px;
}
.terms-col {
}
.follow-us-col {
  margin: 20px 0;
}

.social-links a {
  display: inline-block;
  font-size: 2.5rem;
  text-align: center;
  background-color: #bbbbbb;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 15px;
  transition: all 0.5s ease;
}

.social-links a:hover {
  background-color: white;
}

.copyright {
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 20px;
}

/* for changing the color of logo */
.filter-invert {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg)
    brightness(104%) contrast(101%);

  height: 3rem;
  margin-right: 1rem;
}

/* responsive */
/* @media (max-width: 767px) {
  .foot-col {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .foot-col {
    width: 100%;
    margin-bottom: 30px;
  }
} */

@media (min-width: 650px) {
  .visible {
    visibility: visible;
  }
  .logo-col {
    display: block;
    padding-bottom: 50px;
  }
  .logo-items {
    padding-bottom: 20px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin: 0px;
    padding: 40px 10%;
    text-align: left;
  }
  .company-col ul,
  .terms-col ul,
  .follow-us-col {
    margin: 0px;
  }
  .company-col h2::before,
  .terms-col h2::before,
  .follow-us-col h2::before {
    left: 0;

    margin-left: 0;
    margin-right: 0;
  }
}
