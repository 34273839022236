.submenu-small {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* flex-direction: column; */
  /* justify-content: center; */
  text-align: center;
}
.submenu-small h3 {
  padding-top: 2rem;
  font-size: 2rem;
  font-weight: bold;
}
.submenu-small ul {
  padding-top: 1rem;
  margin-top: 1rem;
}

.submenu-small li {
  font-size: 1.5rem;
  /* margin-top: 1rem; */
  padding: 1rem;
  border: transparent;
  text-align: left;
}
.submenu-small li a:hover {
  color: #0000ff;
}
.active-btn {
  box-shadow: #e068eb 1px 1px 10px 1px;
}
.center {
  text-align: center;
}
