svg-style {
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly; */
}
.txt {
  text-align: center;
  font-size: 1rem;
  margin-top: -1rem;
  margin-bottom: 3rem;
}
@media (min-width: 650px) {
  .txt {
    font-size: 1.7rem;
    margin-top: -5rem;
    margin-bottom: 4rem;
  }
}
