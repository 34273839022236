.syllabus-heading{
    font-size: 3rem;
    color: #7420d5;
}
.syllabus-heading-div{
    display: flex;
    justify-content: space-around;
    
}
.arrows{
    font-size: 1rem;
    cursor: pointer;
    color: #737171;
    padding: 0 5px;

}
.btn-vis{
    display:block
}
.course-item-card{
    /* position: relative; */
    width: 70%;
    margin: 30px auto;
    background-color: #f5f5f5;
    border: 2px;
    border-color: #737171;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: left;
    padding:30px;

}
.course{
    position: relative;
    width: 80%;
    margin: 30px auto;
   
   

}
.topic-layout{
    font-size: 1.4rem;
    /* margin-top: 1.4rem;*/
    padding-left: 2rem; 
    border: transparent;
    text-align: left;
    color: rgb(61, 60, 60);
}
ul{
    list-style: none;
    
}
.heading-layout{
    font-size: 1.5rem;
     margin-top: 1.4rem;
    
    /*padding: 1rem; */
   
    border: transparent;
    text-align: left;
    

}
.middling{
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin:30px auto
}
.text-text {
    font-size: 1.6rem;
    line-height: 2rem;
    
  }
.right-right{
    margin-top: 25px;
    line-height: 5rem;
}

@media (max-width: 650px) {

    .syllabus-heading{
        font-size: 2rem;
        line-height: 2rem;
        
    }
    .course-item-card{
        /* position: relative; */
        width: 100%;
    }
    .course{
       
        width: 100%;
    }
    .btn-vis{
        display:none
    }
    .middling{
        width:100%;
        display: block;
    }
}
