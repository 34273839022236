.header {
  width: 100%;
  height: 6rem;
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
  align-items: center;
  background-color: #7b36db;
}
.header .logo-item {
  display: flex;
  padding-right: 30px;
}
/* .logo-items {
  flex: 1 0 50%;
  height: 100%;
} */
.header .logo {
  font-size: 2.5rem;
  color: white;
}
.header .filter-invert {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg)
    brightness(104%) contrast(101%);

  height: 3rem;
  margin-right: 1rem;
}
.nav {
}

.nav ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.nav li {
  display: block;
  padding: 1rem;
  font-size: 1.75rem;
  background-color: transparent;
}
/* .nav li:last-child {
  padding-right: 0;
} */
.nav li:hover,
.nav li:active {
  background-color: white;
}
.nav a {
  text-decoration: none;
  color: #cbe6e6;
}

.nav a:hover,
.nav a:active,
.nav a.active {
  background-color: white;
  color: #7b36db;
}
.bars {
  display: none;
  font-size: 3rem;
  cursor: pointer;
  /* background-color: aliceblue; */
  color: #7b36db;
  padding: 4px;
}

@media (max-width: 650px) {
  .bars {
    display: block;
    justify-self: right;
    fill: aliceblue;
    border: 1px solid white;
  }
  .nav ul {
    display: none;
  }

  .nav .show {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 5rem;
    left: 0;
    background-color: #7b36db;
    /* height: 100px; */
    width: 100%;
    z-index: 10;
    font-size: small;
  }
  .nav .show li {
    margin: 7px 0;
  }
  .header {
    height: 5rem;
  }
}
