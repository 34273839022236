.team-wrapper {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.right-team {
  /* flex: 1 0 30%; */
}
.right-team .head {
  display: flex;
  width: 100%;
  /* height: 100%; */
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}
/* .ceo:link,
.ceo:visited,
.ceo:hover,
.ceo:active {
  text-decoration: none;
} */
.right-team .head .ceo {
  text-decoration: none;
  font-size: 3rem;
  margin-right: -20px;
  padding: 5px 2rem 5px 2rem;
  background-color: rgb(221, 221, 218);
  color: black;
}
.right-team .others {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.left-team {
  height: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  /* flex: 1 0 40%; */
}
.left-team div h2 {
  font-size: 3rem;
  /* font-weight: 600; */
}
.left-team div h4 {
  font-size: 2rem;
}
.circular-portrait {
  position: relative;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  margin: 1rem;
  box-shadow: 10px 14px 8px 10px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.circular-portrait:hover {
}
.circular-portrait img {
  width: 100%;
  height: auto;
}

@media (max-width: 915px) {
  .team-wrapper {
    display: block;
  }
}
