.hiddentitle {
  width: 100%;
  margin: 0;
  padding: 0;

  /* height: 300px; */
}
.side-col-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.notice-item-card {
  position: relative;
  height: 300px;
  margin: 10px 10px;
  background-color: #f7f4ce;
  border: 2px;
  border-color: #f7f5f5;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.notice-item-wrapper {
  display: table-cell;

  vertical-align: middle;
  text-align: center;
  padding: 10px;
  font-size: 2rem;
}
.notice-item {
  text-align: center;
}
.notice-heading {
  text-align: center;
  padding: 15px 10px 0 10px;

  font-size: 2rem;
}
.notice-heading hr {
  text-align: center;
}
.notice-content {
  text-align: center;
  /* padding: 10px 20px; */
  font-size: 1rem;
}
.middle-txt {
  display: flex;
}
.link-txt {
  font-size: 1.6rem;
}
.middle-big {
  padding: 0 0px 5px 15px;
  display: flex;
  align-items: flex-start;
  height: 40px;
}
.big-text {
  font-size: 2.5em;
}
.small-text {
  font-size: 1.3rem;
  text-align: left;
  padding-top: 5px;
  padding-left: 5px;
  line-height: 1.2rem;
  align-self: center;
}
.small-text-center {
  font-size: 1.3rem;
  text-align: center;
}
.tutor {
  /* justify-content: last baseline; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
  padding-top: 15px;
}
.links-div {
  margin-top: 10px;
}
