.input-txt {
  font-size: 1.5rem;
  width: 90%;
  margin: 0.3rem 0;
  border: none;
  border-color: transparent;
  border-bottom: rgb(199, 139, 28) 1px solid;
  margin-bottom: 2rem;
  outline: none;
}

.submit-btn {
  background-color: rgb(253, 251, 247) 1px solid;

  padding: 2% 4%;
  border: rgb(199, 139, 28) 1px solid;
  /* width: 100%; */
  font-size: 20px;
  /* -webkit-box-shadow: 1px 1px 4px #7420d5; */
  /* box-shadow: 1px 1px 4px #7420d5; */
}
.address {
  padding-right: 1rem;
}
.submit-btn:hover {
  color: #fff;
  background-color: #7420d5 !important;
  border-color: #7420d5 !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 32, 213, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgba(116, 32, 213, 0.5) !important;
}

/* .submit-btn:active {
  color: #fff;
  background-color: #6618c0 !important;
  border-color: #7420d5 !important;
} */
.form-inputs p {
  color: red;
  margin-top: -15px;
  font-size: 1.1rem;
}
.nohvr {
  background-color: rgb(253, 251, 247) 1px solid;
  outline: none;
  pointer-events: none;
}
.ack {
  font-size: 2.1rem;
}
