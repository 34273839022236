@import url(https://fonts.googleapis.com/css2?family=MuseoModerno&family=Noto+Sans&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: MuseoModerno, 'Liberation Mono', 'Courier New', monospace;
}
html {
  /* 10 rem = 100px */
  font-size: 62.5%;
}

.title {
  font-size: 30px;
  font-weight: bold;
  margin: 4rem 0 1rem 0;
}
.subtitle {
  font-size: 20px;
  color: #4a4b4d;
  margin: 0rem 0 1rem 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* @media (min-width: 700px) {
  .central-layout {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    margin-left: 30px;
  }

} */
.central-wrapper {
  width: 100%;
  height: auto;
  padding: 0 10%;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.main-col {
  flex: 1 1;
}
.side-col {
  flex: 0 0 25%;
}
@media (max-width: 1225px) {
  .side-col {
    flex: 0 0 33%;
  }
}

@media (max-width: 915px) {
  .central-wrapper {
    display: block;
  }
}

.sliderimg {
  /* width: 100%;
  height: 330px;
  object-fit: cover; */
  width: 100%;
  height: auto;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  position: absolute;
  bottom: 50%;
  width: auto;
  font-size: 40px;
  color: rgb(198, 228, 28);
}
.alice-carousel__prev-btn:hover {
  color: rgb(198, 228, 28);
}
.alice-carousel__next-btn {
  right: 0;
}

.top-text {
  margin-top: 4rem;
}
.centre-title {
  font-size: 3rem;
  text-align: center;
}
.subtitle {
  text-align: center;
  color: black;

  font-size: 2rem;
}
/*  profolio images////////////// */

.portfolio-items-wrapper {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr; */

  display: flex;
  flex-wrap: wrap;
}
.flex-wrap {
  flex: 1 0 30%;
}
.portfolio-item-wrapper {
  position: relative;
  margin: 10px 10px;
  background-color: #7b36db;
  border: 2px;
  border-color: #f7f5f5;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.portfolio-img-bg {
  height: 300px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  /* box-shadow: 3px 3px #d69a9a; */
}
.img-text-wrapper {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  /* since the direction is column , the centering horizontally has to be done with "align-items" and "width" properties */
  align-items: center;
  width: 100%;

  /* since the direction is column , the centering vertically has to be done with "justify-content" and "height" properties */
  justify-content: center;
  height: 100%;

  padding: 1rem;
}

.cardtitle {
  color: transparent;
  font-size: 2rem;
  text-align: center;
  transition: 0.5s;
}
.cardtitle-sm {
  color: transparent;
  font-size: 1.5rem;
  font-style: italic;

  transition: 0.5s;
}
.logo-wrapper {
  /* width: 50%; */
  margin-bottom: 10px;
  font-size: 3rem;
  color: transparent;
  transition: 0.5s;
}

/* ///on mouseover */
.cardtitle-mouseover {
  color: rgb(237, 241, 241);

  /* font-weight: bold; */
}
.cardtitle2-mouseover {
  color: rgb(237, 241, 241);
  text-align: center;

  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
}
.image-darken {
  transition: 0.5s;
  -webkit-filter: opacity(0);
          filter: opacity(0);
}
.logo-image {
  width: 10%;
}
@media (max-width: 1225px) {
  .flex-wrap {
    flex: 1 0 50%;
  }
  .subtitle {
    font-size: 1.7rem;
  }
}
@media (max-width: 915px) {
  .flex-wrap {
    flex: 1 0 100%;
  }
}

.hiddentitle {
  width: 100%;
  margin: 0;
  padding: 0;

  /* height: 300px; */
}
.side-col-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.notice-item-card {
  position: relative;
  height: 300px;
  margin: 10px 10px;
  background-color: #f7f4ce;
  border: 2px;
  border-color: #f7f5f5;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.notice-item-wrapper {
  display: table-cell;

  vertical-align: middle;
  text-align: center;
  padding: 10px;
  font-size: 2rem;
}
.notice-item {
  text-align: center;
}
.notice-heading {
  text-align: center;
  padding: 15px 10px 0 10px;

  font-size: 2rem;
}
.notice-heading hr {
  text-align: center;
}
.notice-content {
  text-align: center;
  /* padding: 10px 20px; */
  font-size: 1rem;
}
.middle-txt {
  display: flex;
}
.link-txt {
  font-size: 1.6rem;
}
.middle-big {
  padding: 0 0px 5px 15px;
  display: flex;
  align-items: flex-start;
  height: 40px;
}
.big-text {
  font-size: 2.5em;
}
.small-text {
  font-size: 1.3rem;
  text-align: left;
  padding-top: 5px;
  padding-left: 5px;
  line-height: 1.2rem;
  align-self: center;
}
.small-text-center {
  font-size: 1.3rem;
  text-align: center;
}
.tutor {
  /* justify-content: last baseline; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
  padding-top: 15px;
}
.links-div {
  margin-top: 10px;
}

.syllabus-heading{
    font-size: 3rem;
    color: #7420d5;
}
.syllabus-heading-div{
    display: flex;
    justify-content: space-around;
    
}
.arrows{
    font-size: 1rem;
    cursor: pointer;
    color: #737171;
    padding: 0 5px;

}
.btn-vis{
    display:block
}
.course-item-card{
    /* position: relative; */
    width: 70%;
    margin: 30px auto;
    background-color: #f5f5f5;
    border: 2px;
    border-color: #737171;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: left;
    padding:30px;

}
.course{
    position: relative;
    width: 80%;
    margin: 30px auto;
   
   

}
.topic-layout{
    font-size: 1.4rem;
    /* margin-top: 1.4rem;*/
    padding-left: 2rem; 
    border: transparent;
    text-align: left;
    color: rgb(61, 60, 60);
}
ul{
    list-style: none;
    
}
.heading-layout{
    font-size: 1.5rem;
     margin-top: 1.4rem;
    
    /*padding: 1rem; */
   
    border: transparent;
    text-align: left;
    

}
.middling{
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin:30px auto
}
.text-text {
    font-size: 1.6rem;
    line-height: 2rem;
    
  }
.right-right{
    margin-top: 25px;
    line-height: 5rem;
}

@media (max-width: 650px) {

    .syllabus-heading{
        font-size: 2rem;
        line-height: 2rem;
        
    }
    .course-item-card{
        /* position: relative; */
        width: 100%;
    }
    .course{
       
        width: 100%;
    }
    .btn-vis{
        display:none
    }
    .middling{
        width:100%;
        display: block;
    }
}

.team-wrapper {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.right-team {
  /* flex: 1 0 30%; */
}
.right-team .head {
  display: flex;
  width: 100%;
  /* height: 100%; */
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}
/* .ceo:link,
.ceo:visited,
.ceo:hover,
.ceo:active {
  text-decoration: none;
} */
.right-team .head .ceo {
  text-decoration: none;
  font-size: 3rem;
  margin-right: -20px;
  padding: 5px 2rem 5px 2rem;
  background-color: rgb(221, 221, 218);
  color: black;
}
.right-team .others {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.left-team {
  height: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  /* flex: 1 0 40%; */
}
.left-team div h2 {
  font-size: 3rem;
  /* font-weight: 600; */
}
.left-team div h4 {
  font-size: 2rem;
}
.circular-portrait {
  position: relative;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  margin: 1rem;
  box-shadow: 10px 14px 8px 10px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.circular-portrait:hover {
}
.circular-portrait img {
  width: 100%;
  height: auto;
}

@media (max-width: 915px) {
  .team-wrapper {
    display: block;
  }
}

.projectDetail-wrapper {
  width: 100%;
  padding: 0 10%;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.small-menu {
  display: none;
}
.left {
  text-align: left;
  margin: 0;
  padding: 0;
  flex: 0 0 200px;
}
/*  */
.left li {
  margin: 0;
  padding: 0;
}

h1 {
  color: #7420d5;
}

.right {
  flex: 1 1;
  margin: 0 0 1rem 3rem;
  display: flex;
  flex-direction: column;
}
.projTitle {
  margin: 30px 0;
  text-align: center;
  width: 100%;
  font-size: 3.4rem;
  text-shadow: 1.5px 1.5px #424142;
}
.projImg {
  /* text-align: right; */
  width: 100%;

  height: auto;
  object-fit: cover;
}

.lessons {
  text-align: center;
  color: #7420d5;
  font-size: 25px;
  font-weight: bold;
  text-decoration: underline;
}
.summary {
  border: 2px solid #7420d5;
}

.summaryTable {
  font-size: 2rem;
  text-align: left;
  /* margin: 2rem;
  padding: 4rem 4rem; */
}
.summaryTableHeading {
  /* width: 30%; */
  /* padding-left: 200px; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 12rem;
  padding-right: 3rem;
  font-weight: bold;
  font-size: 2.2rem;
  vertical-align: top;
}
.summaryTableHDetail {
  /* width: 60%; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0;
  padding-right: 12rem;
  vertical-align: top;
}

/* .lesson-titles li {
  font-size: 20px;
  width: 500;
  overflow: 'auto';
  border: '1px solid black';
} */

.card {
  padding: 1rem;
  margin-left: 1rem;
  margin-right: auto;
  width: 100%;
  padding: 5rem 0;
}

.svg-wrapper {
  margin: 0 auto;
}

.lesson-titles {
  margin-top: 3rem;
  text-align: left;
}
.lesson-titles h2 {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}
.lesson-titles p {
  font-size: 2rem;
  font-style: italic;
  text-align: center;
}
.subHeading {
  margin-left: 3rem;
  font-size: 2rem;
  padding: 0 50px;
}
.sub-subheading {
  font-size: 2rem;
  list-style-type: decimal;
  padding: 0 10px;

  /* margin-left: 3rem; */
}
.sub-subheading:hover {
  color: blue;
  cursor: pointer;
}
.objectives {
  font-size: 1.85rem;
  font-weight: normal;
  list-style-type: none;
  margin-left: 1.5rem;
  color: black;

  cursor: context-menu;
}

.objectives:hover {
  color: black;
}

.svg-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.svg-item {
  flex: 10% 1;
}
.item-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0;
  padding: 20px 50px;
  background-color: #ffffff;
  border: 2px;
  border-color: #f7f5f5;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 650px) {
  .right {
    flex: 0 0 100%;
    margin: 0;
  }
  .left {
    display: none;
    flex: 100% 1;
  }
  .projectDetail-wrapper {
    flex-wrap: wrap;
  }
  .small-menu {
    display: block;
  }
  .projectDetail-wrapper {
    display: block;
  }
  .summaryTable {
    font-size: 1.6rem;
    padding: 10px 20px;
  }
  .summaryTableHeading {
    font-size: 1.6rem;
    padding-left: 2rem;
  }
  .summaryTableHDetail {
    padding-right: 2rem;
  }
  .subHeading {
    font-size: 1.6rem;
    padding: 0 10px;
  }

  .sub-subheading,
  .objectives {
    font-size: 1.6rem;
  }
  .item-wrapper {
    padding: 10px 20px;
  }
}

.submenu h3 {
  padding-top: 2rem;
  font-size: 2rem;
  font-weight: bold;
}
.submenu ul {
  padding-top: 1rem;
  margin-top: 1rem;
}

.submenu li {
  font-size: 1.6rem;
  margin-top: 1.4rem;
  padding: 1rem;
  border: transparent;
  text-align: left;
  color: gray;
}
.submenu li a:hover {
  color: #0000ff;
}
.active-btn {
  box-shadow: #e068eb 1px 1px 10px 1px;
}

.submenu-small {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* flex-direction: column; */
  /* justify-content: center; */
  text-align: center;
}
.submenu-small h3 {
  padding-top: 2rem;
  font-size: 2rem;
  font-weight: bold;
}
.submenu-small ul {
  padding-top: 1rem;
  margin-top: 1rem;
}

.submenu-small li {
  font-size: 1.5rem;
  /* margin-top: 1rem; */
  padding: 1rem;
  border: transparent;
  text-align: left;
}
.submenu-small li a:hover {
  color: #0000ff;
}
.active-btn {
  box-shadow: #e068eb 1px 1px 10px 1px;
}
.center {
  text-align: center;
}

svg-style {
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly; */
}
.txt {
  text-align: center;
  font-size: 1rem;
  margin-top: -1rem;
  margin-bottom: 3rem;
}
@media (min-width: 650px) {
  .txt {
    font-size: 1.7rem;
    margin-top: -5rem;
    margin-bottom: 4rem;
  }
}

.teamDetail-wrapper {
  width: 100%;
  padding: 5% 10%;
  margin: 0;
  display: block;

  font: verdana;
  font-size: medium;
}
.left-side {
  margin: auto;
  text-align: center;
}
.img-size {
  width: 250px;
}
.right-side {
  width: 100%;
}
.solomon-exp ul {
  list-style-type: none;
  padding-left: 20px;
  line-height: 160%;
}
.blueline {
  width: 100%;
  height: 0.5px;
  background-color: rgb(179, 180, 245);
}
.paddingBetweenCols td {
  padding: 0 15px 10px 0;
  vertical-align: top;
}
.date-style {
  font-size: small;
  width: 130px;
}
.position {
  font-weight: bold;
}
.company {
  color: grey;
  font-size: small;
}
.work {
  font-size: medium;
}
.back-button {
  border: 1px solid #7b36db;

  padding: 5px 10px;
  color: #7b36db;
  margin-bottom: 10px;
  cursor: pointer;
}
.back-button:hover,
.back-button:link {
  text-decoration: none;
  background-color: #7b36db;
  color: azure;
}
.right-side .header-right {
  margin-top: 10px;
  text-align: center;
}

@media (min-width: 650px) {
  .teamDetail-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .left-side {
    margin-top: 0px;
    margin-left: 50px;
    margin-right: 50px;
    text-align: left;
  }
  .right-side {
    width: 50%;
  }
}

.central-wrapper {
  width: 100%;
  height: auto;
  padding: 0 10%;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.left-col {
  flex: 1 1;
  margin: 5%;
}
.right-col {
  flex: 0 0 50%;
  margin: 5%;
}
.left-col ul {
  list-style-type: none;
  font-size: large;
}
.left-col ul li {
  padding-bottom: 5%;
}

.title {
  text-align: left;
  padding-bottom: 4rem;
}

.img-fluid {
  margin-bottom: 9rem;
}
/* @media (min-width: 700px) {
  .central-layout {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    margin-left: 30px;
  }

} */

@media (max-width: 1225px) {
  .right-col {
    flex: 100% 1;
  }
  .img-fluid {
    height: 100px;
    width: 100px;
  }
}

@media (max-width: 915px) {
  .central-wrapper {
    display: block;
    padding: 5%;
  }
}

.input-txt {
  font-size: 1.5rem;
  width: 90%;
  margin: 0.3rem 0;
  border: none;
  border-color: transparent;
  border-bottom: rgb(199, 139, 28) 1px solid;
  margin-bottom: 2rem;
  outline: none;
}

.submit-btn {
  background-color: rgb(253, 251, 247) 1px solid;

  padding: 2% 4%;
  border: rgb(199, 139, 28) 1px solid;
  /* width: 100%; */
  font-size: 20px;
  /* -webkit-box-shadow: 1px 1px 4px #7420d5; */
  /* box-shadow: 1px 1px 4px #7420d5; */
}
.address {
  padding-right: 1rem;
}
.submit-btn:hover {
  color: #fff;
  background-color: #7420d5 !important;
  border-color: #7420d5 !important;
  box-shadow: 0 0 0 0.2rem rgba(116, 32, 213, 0.5) !important;
}

/* .submit-btn:active {
  color: #fff;
  background-color: #6618c0 !important;
  border-color: #7420d5 !important;
} */
.form-inputs p {
  color: red;
  margin-top: -15px;
  font-size: 1.1rem;
}
.nohvr {
  background-color: rgb(253, 251, 247) 1px solid;
  outline: none;
  pointer-events: none;
}
.ack {
  font-size: 2.1rem;
}

/* .main {
  margin: 0;
  width: 100%;
  padding: 0 10%;
  
} */

.header {
  width: 100%;
  height: 6rem;
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
  align-items: center;
  background-color: #7b36db;
}
.header .logo-item {
  display: flex;
  padding-right: 30px;
}
/* .logo-items {
  flex: 1 0 50%;
  height: 100%;
} */
.header .logo {
  font-size: 2.5rem;
  color: white;
}
.header .filter-invert {
  -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg)
    brightness(104%) contrast(101%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg)
    brightness(104%) contrast(101%);

  height: 3rem;
  margin-right: 1rem;
}
.nav {
}

.nav ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.nav li {
  display: block;
  padding: 1rem;
  font-size: 1.75rem;
  background-color: transparent;
}
/* .nav li:last-child {
  padding-right: 0;
} */
.nav li:hover,
.nav li:active {
  background-color: white;
}
.nav a {
  text-decoration: none;
  color: #cbe6e6;
}

.nav a:hover,
.nav a:active,
.nav a.active {
  background-color: white;
  color: #7b36db;
}
.bars {
  display: none;
  font-size: 3rem;
  cursor: pointer;
  /* background-color: aliceblue; */
  color: #7b36db;
  padding: 4px;
}

@media (max-width: 650px) {
  .bars {
    display: block;
    justify-self: right;
    fill: aliceblue;
    border: 1px solid white;
  }
  .nav ul {
    display: none;
  }

  .nav .show {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 5rem;
    left: 0;
    background-color: #7b36db;
    /* height: 100px; */
    width: 100%;
    z-index: 10;
    font-size: small;
  }
  .nav .show li {
    margin: 7px 0;
  }
  .header {
    height: 5rem;
  }
}

/* .................... */

.footer-wrapper {
  width: 100%;

  background-color: #7420d5;
  color: white;
}
.footer {
  margin: 0;
  text-align: center;
  padding: 40px 10%;
}
.visible {
  visibility: hidden;
  border-top: 1px solid rgb(241, 234, 234);
  height: 2px;
}
.logo-col {
  display: flex;
  justify-content: last baseline;
  align-items: center;
}
.logo-item {
  display: flex;
  padding-right: 30px;
}
.company-col,
.terms-col {
  padding-right: 30px;
}
.follow-us {
}
.company-col h2,
.terms-col h2,
.follow-us-col h2 {
  text-transform: capitalize;
  font-size: 2.2rem;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
}
.company-col h2::before,
.terms-col h2::before,
.follow-us-col h2::before {
  content: '';
  position: absolute;
  /* left: 0; */
  bottom: -8px;
  background-color: rgb(241, 234, 234);
  height: 2px;
  box-sizing: border-box;
  width: 50px;
  /* for center aligning */
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.company-col ul,
.terms-col ul {
  text-transform: capitalize;
  list-style-type: none;
  margin: 40px 0;
}
.company-col ul li,
.terms-col ul li {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #e0dcdc;
  transition: all 0.5s ease;
}
.company-col ul li:hover,
.terms-col ul li:hover {
  color: white;
  padding-left: 10px;
}
.terms-col {
}
.follow-us-col {
  margin: 20px 0;
}

.social-links a {
  display: inline-block;
  font-size: 2.5rem;
  text-align: center;
  background-color: #bbbbbb;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 15px;
  transition: all 0.5s ease;
}

.social-links a:hover {
  background-color: white;
}

.copyright {
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 20px;
}

/* for changing the color of logo */
.filter-invert {
  -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg)
    brightness(104%) contrast(101%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg)
    brightness(104%) contrast(101%);

  height: 3rem;
  margin-right: 1rem;
}

/* responsive */
/* @media (max-width: 767px) {
  .foot-col {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .foot-col {
    width: 100%;
    margin-bottom: 30px;
  }
} */

@media (min-width: 650px) {
  .visible {
    visibility: visible;
  }
  .logo-col {
    display: block;
    padding-bottom: 50px;
  }
  .logo-items {
    padding-bottom: 20px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin: 0px;
    padding: 40px 10%;
    text-align: left;
  }
  .company-col ul,
  .terms-col ul,
  .follow-us-col {
    margin: 0px;
  }
  .company-col h2::before,
  .terms-col h2::before,
  .follow-us-col h2::before {
    left: 0;

    margin-left: 0;
    margin-right: 0;
  }
}

