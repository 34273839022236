.central-wrapper {
  width: 100%;
  height: auto;
  padding: 0 10%;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.left-col {
  flex: 1;
  margin: 5%;
}
.right-col {
  flex: 0 0 50%;
  margin: 5%;
}
.left-col ul {
  list-style-type: none;
  font-size: large;
}
.left-col ul li {
  padding-bottom: 5%;
}

.title {
  text-align: left;
  padding-bottom: 4rem;
}

.img-fluid {
  margin-bottom: 9rem;
}
/* @media (min-width: 700px) {
  .central-layout {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    margin-left: 30px;
  }

} */

@media (max-width: 1225px) {
  .right-col {
    flex: 100%;
  }
  .img-fluid {
    height: 100px;
    width: 100px;
  }
}

@media (max-width: 915px) {
  .central-wrapper {
    display: block;
    padding: 5%;
  }
}
