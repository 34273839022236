/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno&family=Noto+Sans&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: MuseoModerno, 'Liberation Mono', 'Courier New', monospace;
}
html {
  /* 10 rem = 100px */
  font-size: 62.5%;
}

.title {
  font-size: 30px;
  font-weight: bold;
  margin: 4rem 0 1rem 0;
}
.subtitle {
  font-size: 20px;
  color: #4a4b4d;
  margin: 0rem 0 1rem 0;
}
