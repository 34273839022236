.projectDetail-wrapper {
  width: 100%;
  padding: 0 10%;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.small-menu {
  display: none;
}
.left {
  text-align: left;
  margin: 0;
  padding: 0;
  flex: 0 0 200px;
}
/*  */
.left li {
  margin: 0;
  padding: 0;
}

h1 {
  color: #7420d5;
}

.right {
  flex: 1;
  margin: 0 0 1rem 3rem;
  display: flex;
  flex-direction: column;
}
.projTitle {
  margin: 30px 0;
  text-align: center;
  width: 100%;
  font-size: 3.4rem;
  text-shadow: 1.5px 1.5px #424142;
}
.projImg {
  /* text-align: right; */
  width: 100%;

  height: auto;
  object-fit: cover;
}

.lessons {
  text-align: center;
  color: #7420d5;
  font-size: 25px;
  font-weight: bold;
  text-decoration: underline;
}
.summary {
  border: 2px solid #7420d5;
}

.summaryTable {
  font-size: 2rem;
  text-align: left;
  /* margin: 2rem;
  padding: 4rem 4rem; */
}
.summaryTableHeading {
  /* width: 30%; */
  /* padding-left: 200px; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 12rem;
  padding-right: 3rem;
  font-weight: bold;
  font-size: 2.2rem;
  vertical-align: top;
}
.summaryTableHDetail {
  /* width: 60%; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0;
  padding-right: 12rem;
  vertical-align: top;
}

/* .lesson-titles li {
  font-size: 20px;
  width: 500;
  overflow: 'auto';
  border: '1px solid black';
} */

.card {
  padding: 1rem;
  margin-left: 1rem;
  margin-right: auto;
  width: 100%;
  padding: 5rem 0;
}

.svg-wrapper {
  margin: 0 auto;
}

.lesson-titles {
  margin-top: 3rem;
  text-align: left;
}
.lesson-titles h2 {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}
.lesson-titles p {
  font-size: 2rem;
  font-style: italic;
  text-align: center;
}
.subHeading {
  margin-left: 3rem;
  font-size: 2rem;
  padding: 0 50px;
}
.sub-subheading {
  font-size: 2rem;
  list-style-type: decimal;
  padding: 0 10px;

  /* margin-left: 3rem; */
}
.sub-subheading:hover {
  color: blue;
  cursor: pointer;
}
.objectives {
  font-size: 1.85rem;
  font-weight: normal;
  list-style-type: none;
  margin-left: 1.5rem;
  color: black;

  cursor: context-menu;
}

.objectives:hover {
  color: black;
}

.svg-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.svg-item {
  flex: 10%;
}
.item-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0;
  padding: 20px 50px;
  background-color: #ffffff;
  border: 2px;
  border-color: #f7f5f5;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 650px) {
  .right {
    flex: 0 0 100%;
    margin: 0;
  }
  .left {
    display: none;
    flex: 100%;
  }
  .projectDetail-wrapper {
    flex-wrap: wrap;
  }
  .small-menu {
    display: block;
  }
  .projectDetail-wrapper {
    display: block;
  }
  .summaryTable {
    font-size: 1.6rem;
    padding: 10px 20px;
  }
  .summaryTableHeading {
    font-size: 1.6rem;
    padding-left: 2rem;
  }
  .summaryTableHDetail {
    padding-right: 2rem;
  }
  .subHeading {
    font-size: 1.6rem;
    padding: 0 10px;
  }

  .sub-subheading,
  .objectives {
    font-size: 1.6rem;
  }
  .item-wrapper {
    padding: 10px 20px;
  }
}
