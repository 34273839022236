/* @media (min-width: 700px) {
  .central-layout {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    margin-left: 30px;
  }

} */
.central-wrapper {
  width: 100%;
  height: auto;
  padding: 0 10%;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.main-col {
  flex: 1;
}
.side-col {
  flex: 0 0 25%;
}
@media (max-width: 1225px) {
  .side-col {
    flex: 0 0 33%;
  }
}

@media (max-width: 915px) {
  .central-wrapper {
    display: block;
  }
}
