.teamDetail-wrapper {
  width: 100%;
  padding: 5% 10%;
  margin: 0;
  display: block;

  font: verdana;
  font-size: medium;
}
.left-side {
  margin: auto;
  text-align: center;
}
.img-size {
  width: 250px;
}
.right-side {
  width: 100%;
}
.solomon-exp ul {
  list-style-type: none;
  padding-left: 20px;
  line-height: 160%;
}
.blueline {
  width: 100%;
  height: 0.5px;
  background-color: rgb(179, 180, 245);
}
.paddingBetweenCols td {
  padding: 0 15px 10px 0;
  vertical-align: top;
}
.date-style {
  font-size: small;
  width: 130px;
}
.position {
  font-weight: bold;
}
.company {
  color: grey;
  font-size: small;
}
.work {
  font-size: medium;
}
.back-button {
  border: 1px solid #7b36db;

  padding: 5px 10px;
  color: #7b36db;
  margin-bottom: 10px;
  cursor: pointer;
}
.back-button:hover,
.back-button:link {
  text-decoration: none;
  background-color: #7b36db;
  color: azure;
}
.right-side .header-right {
  margin-top: 10px;
  text-align: center;
}

@media (min-width: 650px) {
  .teamDetail-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .left-side {
    margin-top: 0px;
    margin-left: 50px;
    margin-right: 50px;
    text-align: left;
  }
  .right-side {
    width: 50%;
  }
}
